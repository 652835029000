//Password strength meter

.pw-strength-checklist {
    display: flex;
    flex-flow: column;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.password-strength-meter {
    position: relative;
    width: 100%;
}

.strength-section {
    color: var(--text-color); /* Default text color */
    font-weight: 700;
    font-style: italic;
    font-size: 14px;
}

.visible {
    display: block;
}

.meter {
    width: 100%;
    height: 10px; 
    background-color: lightgray;
    margin-top: 5px; 
    border-radius: 5px; 
}

.bar {
    height: 100%;
    border-radius: 5px; 
}

.bar.weak {
    width: 25%; 
    background-color: #9E0215;
}

.bar.okay {
    width: 50%; 
    background-color: #FEDC56;
}

.bar.strong {
    width: 75%; 
    background-color: #1E8900;
}

.bar.very-strong {
    width: 100%;
    background-color: #1E8900;
}
  
@media screen and (max-width: 1200px), (max-height: 800px) {
    .pw-strength-checklist {
        gap: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}