@import '../../../styles/site-variables';

.navbar.navbar-fixed-top.navbar-main {
    height: $top-bar-height;
    background-color: #F2F2F2;
    top: 0;
    width: calc(100% - #{$side-panel-width});
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-radius: 0;
    z-index: 29;

    .navigation {
        line-height: $top-bar-height;
        vertical-align: middle;
        padding-left: 25px;
        cursor: pointer;
        color: #1261FF;
        font-weight: 600;
        font-size: 13px;

        .back-arrow {
            height: 10px;
            margin-right: 5px;
        }
    }

    .app-title {
        font-size: 1.4em;
        display: inline-block;
        color: #fdfdfd;
        position: relative;
        text-transform: uppercase;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        padding: 12px 0 0 20px;
        .img-responsive {
            height: 26px;
        }
    }

    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .top-rail-nav {
        position: absolute;
        right: 0;
        top: 1px;
        padding: 8px 27px;
        display: inline-flex;
        color: #FFFFFF;

        .top-rail-icon-container {
            position: relative;
            height: 41px;
            width: 41px;
            text-align: center;
            cursor: pointer;
            outline: 0;

            &:hover {
                cursor: pointer;

                .hovered-nav-icon-background {
                    display: block;
                }
            }

            .hovered-nav-icon-background, .active-nav-icon-background {
                height: 42px;
                width: 42px;
                background-color: #e0e0e0;
                border-radius: 50%;
                position: absolute;
                top: -2px;
                z-index: -1;
            }

            .hovered-nav-icon-background {
                display: none;
            }

            img, .circle {
                height: 24px;
                width: 24px;
                margin-top: 8px;
                margin-left: 1px;
                max-height: none;
            }

            .circle {
                background-color: #1a459c;
                border-radius: 50%;
                display: inline-block;

                .initials {
                    color: #FEFEFE;
                    font-size: 11px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    margin-top: 4px;
                    margin-left: 1px;
                }
            }

        }

        .top-nav-menu {
            position: absolute;
            left: -45px;
            top: calc(#{$top-bar-height} - 8); // subtract 8px for top padding (1px) and margin (7px), which are necessary for arrow
            padding: 1px;
            margin-top: 7px;
            margin-right: 5px;
            background-color: #FFFFFF;
            border-radius: 2px;
            border: none;
            box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.5);
            z-index: 2000;

            .arrow, .arrow::after {
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
            }

            .arrow {
                top: -16px;
                margin-left: -8px;
                border-width: 8px;
                border-bottom-color: transparent;
                left: 50%;

                &::after {
                    border-width: 10px;
                    top: 1px;
                    margin-left: -10px;
                    content: " ";
                    border-top-width: 0;
                    border-bottom-color: #fff;
                }
            }

            .menu-content {
                text-align: left;
                margin-left: 11px;
                margin-bottom: 8px;
                margin-top: 8px;
                padding: 9px 14px;
                overflow: auto;

                ul li {
                    font-size: 14px;
                    color: #1261ff;
                    font-weight: 600;
                    height: 30px;
                    line-height: 30px;
                    margin-top: 10px;
                    list-style: none;
                }
            }
        }

        .user-menu {
            left: -160px;
            width: 278px;

            .arrow {
                left: 249px;
            }

            .menu-content {
                .user-name {
                    font-size: 18px;
                    color: #4a4a4a;
                    height: 24px;
                    line-height: 24px;
                }

                .customer-name {
                    font-size: 13px;
                    color: #7d7d7d;
                    font-weight: 600;
                    min-height: 30px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
            }
        }

        .app-switcher-menu {
            width: 185px;

            .menu-content {
                margin-top: 3px;
                margin-bottom: 3px;
                margin-left: 0;
                padding: 9px 0;

                li {
                    margin-bottom: 5px;
                    padding: 5px 14px 5px 25px;

                    &.app-switcher-link {
                        cursor: pointer;
                    }
                }

                img {
                    height: 20px;
                }
            }
        }
    }
}
