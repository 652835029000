$top-bar-height: 57px;
$side-panel-width: 0px;
$default-font-family: 'Open Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;
$default-font-size: 14px;
$default-text-color: #2f3648;
$default-grey-background-error: #ebebeb;
$opacity7: .7;
$filler-background-color: #e3e3e3;
$dark-grey: #9b9b9b;
$main-background-color: #F2F2F2;
$default-border-color:  #cbcbcb;
