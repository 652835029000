.error-page {
    height: 100vh;
    gap: 15px;
    padding: 25px;

    .error-page__buttons-wrapper {
        display: flex;
        gap: 15px;
    }

    .error-page__button {
        cursor: pointer;
    }
}