@import '../../styles/site-variables';

.dashboard-page {

    .loading-logo-container {
        position: relative;
        height: calc(100vh + #{$top-bar-height});

        .loading-logo {
            position: absolute;
            top: calc(50% - #{$top-bar-height});
            left: calc(42% - #{$top-bar-height});
            transform: translateY(-50%);
        }
    }

    .section-header {
        color: #2F3648;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 33px;
        margin-left: 65px;
        padding-top: 60px;
        margin-bottom: 20px;
    }

    .product-container {
        margin-left: 65px;
        margin-right: 65px;
        padding-bottom: 80px;
        display: flex;
        flex-shrink: 1;
        flex-wrap: wrap;

        &:last-child {
            padding-bottom: 250px;
        }

        .product-tile{
            background-color: #ffffff;
            border: 1px solid #cbcbcb;
            color: #2f3648;
            font-weight: 300;
            font-size: 24px;
            border-radius: 2px;
            margin: 5px;
            height: 175px;
            width: 325px;
            position: relative;

            &:hover{
                position: relative;
                top: -3px;
                cursor: pointer;
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
            }

            img {
                height: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .more-offering-tile {
            background-color: #e5e5e5;
            border: 1px solid #e5e5e5;
            color: #425175;
            font-weight: 300;
            font-size: 24px;
            border-radius: 2px;
            margin: 5px;
            height: 175px;
            width: 325px;
            position: relative;

            & .new-product {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 15px;
                margin-right: 10px;
                height: 25px;
                background-color: #FFB81C;
                border-radius: 4px;
                color: #2f3648;
                font-size: 14px;
                font-weight: 700;

                &:before {
                    content: "new";
                    margin: 10px;
                    text-transform: uppercase;
                    line-height: 25px;
                }
            }

            &:hover .product-logo-section {
                height: 55%;
            }

            &:hover .learn-more-section {
                transition: top 300ms ease-in-out;
                top: 0;
                visibility: visible;
            }

            .product-logo-section {
                position: relative;
                height: 100%;

                img {
                    height: 30px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .learn-more-section {
                position: relative;
                top: 45%;
                height: 45%;
                background-color:#2f3648;
                font-weight: 600;
                font-size: 14px;
                color: #ffffff;
                visibility: hidden;

                .learn-more-button {
                    cursor: pointer;
                    margin-top: 10px;
                }

                .short-description-container{
                    position: relative;

                    .short-description {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        margin-top: 5px;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}
