.sign-in-page {
    width: 100vw;
    height: 100vh;
    min-height: 645px;
    text-align: center;
    background-color: white;
    margin: auto;

    .sign-in-page__wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 25px;
    }

    .sign-in-page__form {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-bottom: 25px;
        max-width: 300px;
    }

    .sign-in-page__form-fields {
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        gap: 15px;
        margin-bottom: 5px;
    }


    .sign-in-page__form-field-wrapper {
        position: relative; /* Ensure relative positioning for absolute positioning of the eye icon */
        display: flex;
        flex-flow: row;
        width: 100%;
        align-items: center;
        gap: 10px;
    }

    .eye-icon {
        height: 25px;
        width: 25px;
        min-height: 25px;
        min-width: 25px;
        cursor: pointer;
        position: absolute; /* Position the eye icon absolutely */
        right: -30px; /* Adjust the right position as needed */
        top: 50%; /* Center the eye icon vertically */
    }

    .sign-in-page__form-field {
        display: flex;
        flex-flow: column;
        width: 350px;
    }

    .sign-in-page__form-field-input {
        color: #363636;
        height: 45px;
        border-radius: 10px;
    }

    .sign-in__button { 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        border-radius: 10px;
        margin: 25px 0 25px 0;
        transition: 0.25s;
        text-shadow: 0px 0px 10px rgba(0,0,0,0.2);
        background-size: 200% auto;
        background: linear-gradient(90deg, #E26540 42.19%, #F5B746 99.99%);
        font-size: 1rem;

        &:hover {
            background-image: linear-gradient(to right, #E26540 0%, #F5B746 51%, #E26540 100%);
        }
    }

    .sign-in__forgot-password {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
    }

    .sign-in__forgot-password-btn {
        text-transform: none;
        display: flex;
        font-size: 1rem;
        color: #051942;
        font-weight: 500;
    }


    .sign-in__remember-me-chkbox .ns-checkbox__checkbox{
        border-color: white;
        background-color: transparent;
        color: white;
    }

    .sign-in__contact-support {
        display: flex;
        font-size: 1rem;
        gap: 10px;
        align-items: center;

        a {
            color: #051942;
        }
    }

    .sign-in__contact-support-btn {
        display: flex;
        align-items: flex-end;
        text-decoration: underline black;
        color: #051942;
        text-transform: none;
        font-size: 1rem;
        font-weight: normal;
    }

    .sign-in__header {
        text-align: left;
        color: white;
        font-weight: 800;
        font-size: 48px;
        margin-bottom: 15px;
    }

    .sign-in__header-logo {
        height: 55px;
        width: 55px;
        min-height: 55px;
        min-width: 55px;
        margin-bottom: 15px;
    }

    .sign-in__header-resetPW-success {
        border: 1px solid #62AC4D;
        background: #E9F3E6;
        font-size: 1rem;
        padding: 15px;
        color: #0C3700;
        border-radius: 10px;
        display: flex;
        flex-flow: row;
        gap: 15px;
        align-items: center;
    }

    .sign-in__header-resetPW-success-icon {
        height: 25px;
        width: 25px;
        max-height: 25px;
        max-width: 25px;
    }

    .sign-in__form-field-label {
        display: flex;
        color: white;
        margin-bottom: 5px;
    }

    .input-group {
        margin-bottom: 10px;
    }

    .auth-error {
        color: #9E0215;
        text-align: left;
        font-style: italic;
        font-size: 1rem;
        font-weight: 700;
    }
}


@media screen and (max-width: 1440px), (max-height: 1024px) {
    .sign-in-page .sign-in__header {
        margin: 0;
        font-size: 35px;
    }

    .sign-in-page .sign-in__header-resetPW-success {
        font-size: .9rem;
        padding: 8px;
    }

    .sign-in-page .sign-in-page__form-fields {
        gap: 5px;
    }

    .sign-in-page .eye-icon {
        top: 50%;
    }

    .sign-in-page .sign-in__button {
        margin: 15px 0px;
    }

    .sign-in-page .auth-error {
        font-size: .875rem;
    }
}

@media screen and (max-width: 1024px), (max-height: 768px) {
    .sign-in-page .sign-in-page__wrapper {
        background: linear-gradient(to right, #9B8FFF, #4478FF) !important;
        background-size: 200% 100%;
        margin: 0px;
    }

    html {
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }

    .sign-in-page {
        width: 110vw;
        height: 110vh;
    }
}

@media screen and (max-width: 1023px) {
    .marketing-panel {
        display: none !important; //hide the marketing panel on screen smaller than tablet landscape
    }

    .sign-in-page .sign-in-page__wrapper {
        padding-top: 25px;
        height: 100%;
    }
}
